<template>
    <div v-if="isMounted" id="applicant-loan-simulator" class="p-2">
        <vx-card>
            <h1>Comienza tu solicitud hoy mismo</h1>
            <div class="vx-row">
                <div :key="index" v-for="(loan, index) in loanOrder" class="vx-col sm:w-full md:w-1/2 lg:w-1/4 xxl:w-1/4">
                    <LoanCardDetail :hasLoans="hasLoans" :loanType="loan.loanType" :loan="getLoan(loan.loanType, loan.familyGroupId)">
                    <template v-slot:title>{{ loan.title }}</template>
                    <template v-slot:desc>{{ loan.desc }}</template>
                    </LoanCardDetail>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import LoanCardDetail from "@views/applicant/loan-requests/components/home/LoanCardDetail.vue";
import formatHelper from '@mixins/formatHelper';
export default {
    name: "ApplicantLoansSimulator",
    components: {
        LoanCardDetail
    },
    mixins: [formatHelper],
    data: () => ({
        projects: [],
        isMounted: null,
        loanOrder: [],
        hasLoans: false
    }),
    async mounted() {
        this.isMounted = false;
        await this.showLoading(true);
        await this.getLoans();
        this.isMounted = true;
        await this.showLoading(false);
    },
    computed: {
        getLoanTitle() {
            if(this.UserPersonType == 0) {
                return "PYME";
            } else {
                if(this.ApplicantTaxProfile == 1) {
                    return "PYME";
                }

                return "Personal";
            }
        },
        getLoanDesc() {
            if(this.UserPersonType == 0) {
                return `Crece tu negocio con hasta ${this.costFormatNoCents(this.LoanLimits.max_limit)}.`;
            } else {
                if(this.ApplicantTaxProfile == 1) {
                    return `Crece tu negocio con hasta ${this.costFormatNoCents(this.LoanLimits.max_limit)}.`;
                }

                return `Te prestamos desde ${this.costFormatNoCents(this.LoanLimits.min_limit)} para mejorar tu vida y la del planeta.`;
            }
        },
        isPFAE() {
            return this.UserPersonType == 1 && this.ApplicantTaxProfile == 1;
        }
    },
    methods: {
        async getLoans() {
            try {
                const res = await axios.get(`api/loan-onboarding/get/${this.ApplicantId}/loans`);
                this.projects = res.data.data;
                this.setLoanOrder();
                this.isMounted = true;
            } catch (error) {
                console.log(error);
                this.failedOperationNotif("Algo salió mal.");
            }
        },
        getLoan(loanType, family_group_id = null) {
            if(loanType == 2 || loanType == 1) { // Sí es automotriz
                let loans = this.projects.filter(p => (p.loan_type == 1 || p.loan_type == 6)); 
                // console.log(loans);
                if(loans != null) {
                let currentLoan = null;
                loans.forEach(loan => {
                    let fg_location = loan.fg_locations[0];
                    if(fg_location && fg_location.family_group_id == family_group_id) {
                        currentLoan = loan;
                    }
                });
                return currentLoan;
                }
            } else if (loanType == 4) {  // Es PFAE y personal
                let type = 5; // Personal 
                const loan = this.projects.find(p => p.loan_type == type); 
                if(loan != null) {
                    return loan;
                }
            } else if (loanType == 3) { // Sí es personal o pyme
                let type = 4; // Personal 
                // if(this.UserPersonType == 0 || (this.ApplicantTaxProfile == 1)) {
                //   type = 4 // PYME;
                // }
                const loan = this.projects.find(p => p.loan_type == type); 
                if(loan != null) {
                    return loan;
                }
            }

            return null;
        },
        setLoanOrder() {
            // Debe acomodar los créditos en el orden que se deben mostrar según si tiene solicitudes o por tipo de crédito
            // Sí no tiene, se establece el orden por default. (solar, pyme, movilidad y personal)
            const defaultOrder = [
                {
                    loanType: 1,
                    familyGroupId: 1,
                    title: "Crédito Solar",
                    desc: `Obtén hasta ${this.costFormatNoCents(this.LoanLimits.max_limit)} y ahorra usando energía solar.`
                },
                {
                    loanType: 3,
                    familyGroupId: null,
                    title: "Crédito PYME",
                    desc: `Crece tu negocio con hasta ${this.costFormatNoCents(this.LoanLimits.pm_max_limit)}`
                },
                {
                    loanType: 2,
                    familyGroupId: 7,
                    title: "Crédito Automotriz",
                    desc: `Ahórrate la gasolina y cambia al mundo con hasta ${this.costFormatNoCents(this.LoanLimits.max_limit)} de crédito.`
                },
                {
                    loanType: 4,
                    familyGroupId: null,
                    title: "Crédito Personal",
                    desc: `Mejora tus finanzas con hasta ${this.costFormatNoCents(this.LoanLimits.max_limit)}`
                }
            ];
            if(this.projects.length > 0) {
                this.hasLoans = true
                let order = [];
                let loanTypes = [1,3,2,4];
                let solar = this.getLoan(1, 1);
                let pyme = this.getLoan(3);
                let movilidad = this.getLoan(2, 7);
                let personal = this.getLoan(4);

                if(solar) {
                    order.push(defaultOrder.find(d => d.loanType == 1));
                }
                if(pyme) {
                    order.push(defaultOrder.find(d => d.loanType == 3));
                }
                if(movilidad) {
                    order.push(defaultOrder.find(d => d.loanType == 2));
                }
                if(personal) {
                    order.push(defaultOrder.find(d => d.loanType == 4));
                }

                // Tenemos el las solicitudes en la primera posición. Ahora acomodamos los demás créditos.
                loanTypes.forEach(type => {
                    let loan = order.find(o => o.loanType == type);
                    if(loan == null) {
                        let defaultLoan = defaultOrder.find(d => d.loanType == type);
                        order.push(defaultLoan);
                    }
                });

                
                this.loanOrder = order;
            } else {
                this.loanOrder = defaultOrder;
            }
        }
    }

}
</script>