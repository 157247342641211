<template>
    <div id="applicants-home">
        <LoanDeliveredDetail class="mb-4"></LoanDeliveredDetail>
        <ApplicantLoansSimulator></ApplicantLoansSimulator>
    </div>
</template>

<script>
import LoanDeliveredDetail from './home/LoanDeliveredDetail.vue';
import ApplicantLoansSimulator from './home/ApplicantLoansSimulator.vue';
export default {
    name: "ApplicantsHome",
    components: {
        LoanDeliveredDetail,
        ApplicantLoansSimulator
    },
    props: {
        source: String
    }
}
</script>